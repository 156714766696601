@import "../constants";

.top-bar {
  display: flex;
  flex-wrap: wrap-reverse;
  background-color: #212121;
  color: $color-pink;
  justify-content: flex-start;
  align-items: center;

  .broadcaster-dropdown {
    margin-left: 10px;
    width: 180px;

    .selected-broadcaster-icon {
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }

    .btn-primary  {
      background-color: $color-pink;
      color: black;
      border-color: black;
      min-width: 120px;

      &:focus, &:active {
        background-color: $color-pink;
        color: black;
        border-color: black;
      }
    }

    .dropdown-menu.show {
      max-height: 400px;
      overflow-y: auto;
    }

    .dropdown-item {
      padding-left: 10px;

      &:focus, &:active, &:hover {
        background-color: $color-pink;
        color: black;
        border-color: black;
      }

      .broadcaster-dropdown-icon {
        height: 25px;
        width: 25px;
        margin-right: 5px;
      }
    }
  }

  .top-bar-title {
    font-size: 25px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 5px;

    a {
      text-decoration: none;
      color: $color-pink;
      &:hover  {
        color: darken($color-pink, 7%)
      }
    }

    .top-bar-lulclip {
      width: 35px;
      height: 35px;
      margin-left: 10px;
    }
  }
  .top-bar-made-by {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
  }
}

@media (max-width: $narrow-screen-threshold) {
  .top-bar { 
    .top-bar-title {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}