@import "../constants";

.clips-loader {
  display: flex;
  background-color: black;
  color: $color-pink;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  .spinner-border {
    color: $color-pink;
    width: 100px;
    height: 100px;
  }
}

.clips {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .clips-pagination {
    display: flex;
    justify-content: center;
    color: $color-pink;
    margin-bottom: 10px;
    margin-top: 10px;

    .clips-pagination-text {
      margin-left: 10px;
      margin-right: 10px;
      align-self: center;
    }

    button {
      color: $color-pink;
      width: 50px;
    }

    .clips-pagination-first {
      margin-right:5px;
    }

    .clips-pagination-last {
      margin-left: 5px;
    }
  }

  .clips-container {
    .clips-card-deck {
      background-color: black;
      color: $color-pink;
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(350px, 1fr));
      grid-auto-rows: min-content;
    
      .clip-card {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        background-color: #141414;
        display: flex;
        flex-direction: column;
        min-height: 200px;
    
        .clip-card-thumbnail {
          position: relative;
          flex-grow: 1;
    
          transition: 0.2s;
          &:hover {
            transform: translate(3px, -3px);
          }
    
          .clip-card-viewcount {
            position: absolute;
            bottom: 5px;
            left: 5px;
            pointer-events: none;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 3px;
            background-color: rgba(#141414, 0.8);
            font-size: 15px;
          }
    
          .clip-card-createdat {
            position: absolute;
            bottom: 5px;
            right: 5px;
            pointer-events: none;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 3px;
            background-color: rgba(#141414, 0.8);
            font-size: 15px;
          }
    
          .clip-card-thumbnail-img {
            width: 100%;
          }
        }
    
        .clip-card-footer {
          display: flex;
          flex-direction: column;
          min-height: 60px;
          justify-content: space-between;
    
          .clip-card-title {
            font-size: 16px;
            margin: 5px 5px 0px 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
    
            a {
              text-decoration: none;
              color: $color-pink;
              &:hover  {
                color: darken($color-pink, 7%)
              }
            }
          }
      
          .clip-card-creator {
            margin: 5px 5px 5px 5px;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #bfbfbf;
          }
        }
      }
    }
  }
}

.clips-list-noclipsfound {
  display: flex;
  background-color: black;
  color: $color-pink;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  font-size: 20px;
  flex-direction: column;

  .clips-list-noclipsfound-img {
    width: 80px;
    height: 80px;
    margin-left: 10px;
  }
}