@import "../constants";

.search-filter {
  background-color: #141414;
  color: $color-pink;
  min-width: 280px;
  min-height: 200px;
  overflow-y: auto;
  
  .search-filter-form {
    margin: 10px;

    .search-filter-form-title {
      margin-bottom: 10px;
    }

    .search-filter-additional-filters-btn {
      width: 100%;
      color: $color-pink;
      &:hover  {
        color: darken($color-pink, 7%)
      }
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__close-icon::after {
        cursor: pointer;
        background-color: #f4a5b8;
        color: black;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        padding: 2px;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "\00d7";
      }
    }

    .game-select {
      color: black;
    }

    .search-button {
      margin-top: 10px;
      width: 100%;
      background-color: $color-pink;
      color: black;
      border-color: $color-pink;
    }
  }
}

@media (min-width: $narrow-screen-threshold) {
  .search-filter {
    max-width: 280px;
  }
}
