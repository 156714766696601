@import "constants";

#root {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  overflow-y: auto;

  .body {
    height: 100%;
    overflow-y: auto;
    display: flex;
  }
}

@media (max-width: $narrow-screen-threshold) {
  .App .body {
    flex-direction: column;
  }
}
